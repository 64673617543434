import trackerApi from "../api/tracker";
import { navigate } from "gatsby";

export const addSociety = async ({
  logo,
  name,
  url,
  phoneNumber,
  email,
  contactName,
  description,
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const society = await trackerApi.post(
      "/addSociety/",
      {
        token,
        logo,
        name,
        url,
        phoneNumber,
        email,
        contactName,
        description,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    navigate("/app/allSocieties");
    return society.data;
  } catch (error) {
    console.log("error can't create a society : ", error);
  }
};

export const getAllSocieties = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getSociety", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error to get all societies :", error);
  }
};

export const getSocietyById = async (id) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const society = await trackerApi.get(`/getSocietyById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, id },
    });
    return society.data;
  } catch (error) {
    console.log("error can't get a society : ", error);
  }
};

export const updateSocietyById = async ({
  societyId,
  name,
  url,
  phoneNumber,
  email,
  contactName,
  description,
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/updateSociety",
      {
        societyId,
        name,
        url,
        phoneNumber,
        email,
        contactName,
        description,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allSocieties");
  } catch (error) {
    console.log("ERROR can't update a society");
  }
};

export const deleteSociety = async ({ societyId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/deleteSociety/",
      {
        societyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allSocieties");
  } catch (error) {
    console.log("ERROR can't delete a society");
  }
};

export const addSocietyImg = async ({ imgEventsBase64, societyId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const addImageSelected = await trackerApi.post(
      "/imgSociety",
      {
        token,
        imgEventsBase64,
        societyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("addImageSelected :", addImageSelected);
  } catch (error) {
    console.log("ERROR can't add image for society");
  }
};
