import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faEnvelope,
  faLink,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/style/globalStyle.scss";

const Card = ({
  logo,
  name,
  url,
  phoneNumber,
  email,
  contactName,
  description,
  onClick,
}) => {
  return (
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img src={logo} alt=""></img>
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4">{name}</p>
            <a className="subtitle is-6" href={"mailto:" + email}>
              <Icon icon={faEnvelope} size="1x" /> {email}
            </a>
          </div>
        </div>

        <div className="content">
          {description} <br />
          {url && <a href={url.slice(0, 4) === "http" ? url : `http://${url}`}>
              <Icon icon={faLink} size="1x" /> {url}
            </a>}
          .
          <p>
            <Icon icon={faPhone} size="1x" /> {phoneNumber}
          </p>
          <br />
          <p>
            <Icon icon={faAddressBook} size="1x" /> {contactName}
          </p>
        </div>
        <footer className="card-footer level-right">
          <button className="button" onClick={onClick}>
            Voir détails
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Card;
