import React, { useState, useEffect, useContext } from "react";
import CardSociety from "../../components/cardSociety";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
//import img from "../../assets/img/bnc-logo.png";
import { getAllSocieties, getSocietyById } from "../../services/societyService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllSocieties = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const [societies, setSocieties] = useState([]);

  const getAllSocietiesToDisplay = async () => {
    const data = await getAllSocieties();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas de société enregistrée</h1>
        </div>
      );
    }

    setSocieties(data);
  };

  const GoToSociety = async (id) => {
    const society = await getSocietyById(id);
    dispatch({
      type: "get_society",
      payload: society,
    });
    navigate("/app/updateSocietyPage");
  };

  useEffect(() => {
    getAllSocietiesToDisplay();
  }, []);

  return (
    <div className="columns is-multiline">
      {societies.length !== 0 &&
        societies.society.map((society) => (
          <div key={society.name} className="column is-one-third">
            <CardSociety
              logo={
                society.logo
                  ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/society_image/${society._id}.jpg`
                  : null
              }
              name={society.name}
              url={society.url}
              phoneNumber={society.phoneNumber}
              email={society.email}
              contactName={society.contactName}
              description={society.description}
              onClick={() => GoToSociety(society._id)}
            />
          </div>
        ))}
    </div>
  );
};

export default AllSocieties;
